.profile-info__fieldset {
  .form_title {
    width: 60% !important;
    label {
      display: block;
    }
  }
  .form_birthday {
    label {
      display: block;
    }
  }
}

#address_form_container {
  .country_container {
    label {
      display: inline-block;
    }
  }
  .form_element {
    margin: 0 0 10px 0;
  }
}

.cart-item {
  .product-info__spp-status {
    position: absolute;
    bottom: 0;
  }
}

#cboxContent {
  .address-delete {
    @include swap_direction(padding, 5px);
  }
}

.past-purchases-page {
  &__content {
    .past-purchases {
      @include swap_direction(padding, 20px);
      .product--teaser {
        .product__footer {
          .notify-status {
            @include swap_direction(padding, 6px 0);
            a.notify_me {
              @include swap_direction(margin, 0);
              @include swap_direction(padding, 0px 15px);
              line-height: 30px;
            }
          }
          .product-item__out-of-stock {
            position: absolute;
            #{$rdirection}: 0;
            top: 60px;
          }
        }
      }
      li.product {
        margin-bottom: 30px;
      }
    }
  }
}

.favorites-page__content {
  .favorites-board__item {
    margin-bottom: 35px;
  }
}

.account-order-history {
  .past-purchases {
    .past-purchases__item {
      .product--teaser {
        .product__add {
          p.product-item__unavailable-message,
          p.product-item__inactive,
          p.product-item__sold-out,
          p.product-item__out-of-stock {
            float: $rdirection;
            line-height: inherit;
          }
        }
      }
    }
  }
}

.profile-pic-upload__account {
  .btn-instructions {
    height: 5.4em;
  }
}

.order-details-page {
  .order-details {
    .order-details__item {
      word-wrap: break-word;
    }
  }
}
