//breakpoint
$ab-small-down: max-width $width-large - 2; //ipad portrait fixing - max-width 767 px
$ab-large-up: min-width $width-large - 1; //ipad portrait fixing - min-with 768px
$ab-ipad-portrait: $width-large - 1 $width-xlarge - 1;
$ab-ipad-landscape: $bp--large-down;
$ab-mob-portrait: max-width $width-xsmall - 1;

$ab-max-width: 960px;

.appt-book {
  .appointment-select {
    .appointments-container {
      .appointment-select {
        .calendar-head {
          .cal-head-controls {
            .cal-controls.next {
              text-indent: -44px;
            }
            @include breakpoint($ab-ipad-portrait) {
              .cal-controls.next,
              .cal-controls.previous {
                width: 25%;
              }
              .cal-controls.day-time {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }
  .confirmation {
    .confirmation-container {
      .date-time {
        .appt-date-cal {
          background: transparent
            url(/media/export/cms/makeup_services/appointment_booking/appointments/cal_date_picker_icon.png) no-repeat
            16.5em 0.25em/12px 12px;
        }
      }
    }
  }
  .my-appointments {
    .appointment-container {
      .appointment-details {
        .date-time {
          .appt-date-cal {
            background: transparent
              url(/media/export/cms/makeup_services/appointment_booking/appointments/cal_date_picker_icon.png) no-repeat
              16.5em 0.25em/12px 12px;
          }
        }
      }
    }
  }
  &.confirmation {
    .appt-book-page-header {
      .appt-book-page-header-content {
        h2 {
          line-height: 1.6;
        }
      }
    }
  }
}

.appt-book-overlay.my-appointments-overlay {
  .overlay-content-container {
    .canceled {
      .book-new-btn {
        width: 290px;
      }
    }
  }
}

.appointment-nav {
  .submenu__header {
    @include breakpoint($ab-ipad-portrait) {
      width: 32%;
    }
    @include breakpoint($bp--large-up) {
      //@media (min-width: 48.0625em) {
      width: 32%;
    }
    @include breakpoint($ab-small-down) {
      .appointment-nav__link {
        &.appointment-header-nav__link {
          background: #f1f2f2 url('/_onelink_/maciberica/projects/mac/eses2ptes/images/media/export/cms/makeup_services/gnav_hd_make_services_es.png') no-repeat center
            center !important;
        }
      }
      .appointment-header-nav__link-arrow {
        right: 5% !important;
      }
    }
  }
}

@include breakpoint($ab-small-down) {
  .appt-book-overlay {
    &.my-appointments-overlay {
      .overlay-content-container {
        width: 340px;
        margin-left: -170px;
      }
    }
    &.appt-book-overlay {
      .overlay-content-container {
        height: auto;
        min-height: 232px;
      }
    }
  }
  .appt-book {
    &.confirmation {
      .appt-book-page-header {
        .appt-book-page-header-content {
          h2 {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}

@include breakpoint(max-width $width-xsmall - 1) {
  .appt-book {
    .confirm {
      .confirm-container {
        .sign-in-container {
          .sign-in-link-container {
            h4 {
              margin-left: 45px;
            }
          }
        }
      }
    }
  }
}
