.overlay--interstitial {
  .intersitial-language {
    &__button {
      padding-bottom: 0;
    }
    &--es-es,
    &--es-pt {
      border-bottom: 1px solid $color--gray--lighter;
      padding-bottom: 10px;
    }
  }
}

.site-header-formatter {
  .header-nav-section__section {
    @include breakpoint($bp--largest-up) {
      padding: 0 12px;
    }
  }
}

.hero-block {
  &__wrapper {
    #extole_zone_homepage_tout {
      bottom: 0;
      cursor: pointer;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }
}

.select2-drop {
  .select2-results {
    li {
      line-height: normal;
      padding-top: 10px;
    }
  }
}
