@if $password_strengthen {
  .password-field {
    &__info {
      @include breakpoint($bp--medium-up) {
        bottom: 30%;
      }
    }
  }
  .profile-info {
    &__change_pwd_link {
      @include breakpoint($bp--medium-up) {
        text-align: #{$rdirection};
        margin-#{$rdirection}: 32%;
      }
    }
  }
  .password-reset {
    .password-field {
      &__info {
        @include breakpoint($bp--medium-up) {
          bottom: -25px;
        }
      }
    }
  }
}
