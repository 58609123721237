.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.site-container {
  min-height: 640px;
}

body {
  #site-header {
    #email_sms_signup {
      #form--email_sms_signup--field--MOBILE_NUMBER {
        display: none;
      }
    }
  }
  .site-container {
    .prod_inv_status-2,
    .prod_inv_status-3 {
      .product__footer {
        .product__add-to-bag {
          display: none;
        }
      }
    }
    .store-locator {
      .results_panel {
        .results_side {
          .sub-options {
            display: none;
          }
          .doors_results-wrapper {
            height: 680px;
          }
        }
      }
    }
  }
}

.form--newsletter__form-items {
  .form-wrapper {
    .form--newsletter__submit {
      height: 29px !important;
      line-height: 35px !important;
    }
  }
}

.homepage-popup {
  #cboxContent {
    #cboxLoadedContent {
      overflow: hidden !important;
    }
  }
}
