$border_color: #dbdbdb;
$background_color: #d4d4d4;

#recommended-products-panel {
  .checkout__panel-content {
    margin-top: 20px;
  }
  .recommended-product-items {
    position: relative;
    padding-bottom: 80px;
  }
}

.viewcart {
  #order-summary-panel {
    border-top: 1px solid $border_color;
  }
}

#checkout-sidebar {
  .additional_info {
    margin-top: 1em;
  }
}

.samples {
  .product.deactivate {
    .sample-select-button {
      background-color: $background_color;
      cursor: auto;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .picker-checkbox {
    .picker-handle {
      display: none;
    }
  }
  .samples-panel {
    border-top: 1px solid $border_color;
    padding-top: 15px;
  }
  .product-list {
    li {
      float: left;
      width: auto;
      padding: 10px;
      height: auto;
    }
  }
  .checkout__panel-title {
    display: block;
  }
  .sample-select-button {
    width: 125px;
  }
}

.shipping .checkout-progress__shipping,
.review .checkout-progress__review,
.billing .checkout-progress__billing,
.confirm .checkout-progress__confirm {
  font-weight: bold;
}

.checkout {
  ol.checkout-progress {
    li {
      float: left;
      margin-left: 1.5em;
      width: 12.5em;
      list-style-type: decimal;
      margin-top: 1.5em;
    }
  }
  .address-container {
    width: 40%;
    float: left;
  }
  .address_controls {
    #choose-address {
      float: left;
      margin-top: 15px;
    }
  }
  address {
    font-style: normal;
  }
  #review-instructions p {
    margin-top: 15px;
  }
  .checkout__sidebar {
    .tab-bar {
      display: none;
    }
    .samples-panel__submit-container {
      display: none;
    }
    #favorites-panel,
    #past-purchases-panel {
      .product__desc {
        .add-to-cart {
          .btn {
            &.btn-mini {
              margin-top: 10px;
              @include swap_direction(padding, 0 10px);
              height: 50px;
              line-height: normal;
              white-space: normal;
              @include breakpoint(($width-large - 1 $width-xlarge + 1) (orientation landscape)) {
                @include swap_direction(padding, 0 4px);
              }
            }
          }
        }
      }
    }
  }
  .mobile-breadcrumb {
    display: none;
  }
  .country-id {
    label {
      display: block;
    }
  }
  .checkout-header {
    .checkout-header__samples {
      @include swap_direction(padding, 1rem 0);
      text-align: center;
      @include breakpoint($bp--medium-up) {
        width: 100%;
        text-align: center;
        padding-#{$rdirection}: 0;
        @include breakpoint($bp--large-up) {
          width: 74%;
          padding-#{$ldirection}: 15%;
        }
        @include breakpoint($bp--xlarge-up) {
          position: relative;
          padding-#{$ldirection}: 15%;
        }
      }
    }
    #top-viewcart-buttons {
      @include breakpoint($bp--large-up) {
        width: 100%;
        @include swap_direction(padding, 0 0em);
      }
      .continue-buttons {
        width: 74%;
        padding-#{$rdirection}: 0%;
        padding-#{$ldirection}: 15%;
        text-align: center;
      }
    }
  }
  .panel {
    .name-fields {
      .form-item.last-name {
        float: none;
      }
    }
    .checkout__content {
      .panel {
        .name-fields {
          .last-name {
            float: left;
          }
        }
      }
    }
  }
}

.site-container {
  .checkout {
    .checkout__sidebar {
      .viewcart-header {
        display: inline-block;
        width: 100%;
        h2 {
          width: auto;
          @include swap_direction(padding, 0);
          @include swap_direction(margin, 0);
          line-height: 25px;
          float: $ldirection;
        }
        #header-item-count {
          @include swap_direction(padding, 0);
          float: $rdirection;
          margin-top: 5px;
        }
      }
      .viewcart-panel__content {
        .cart-item__total {
          @include breakpoint(($width-large - 1 $width-xlarge + 1) (orientation landscape)) {
            clear: both;
          }
        }
      }
      .panel.links-panel {
        margin-top: 0;
      }
    }
  }
}

.transaction-panel-details {
  margin-top: 2em;
  .trans_detail_item {
    float: left;
    margin-bottom: 2em;
    margin-right: 2em;
    position: relative;
    width: 23.4em;
    word-wrap: break-word;
    .change_link {
      position: absolute;
      top: 0.6em;
      right: 0;
    }
    dd {
      margin: 5px 0 0 10px;
    }
  }
}

.shipping {
  #address_form_container,
  #billing-address-info-panel {
    .form_element {
      margin: 12px 0;
    }
  }
}

.billing {
  .direct-debit label {
    display: block;
  }
  .payment-type {
    float: left;
    margin-right: 0.8em;
  }
  #payment-type-cc {
    margin-top: 15px;
  }
}

.confirm {
  #print-order {
    margin-top: 10px;
  }
}

.checkout {
  .checkout__content {
    #shipping-panel.loading {
      text-transform: none;
      margin: 1em 0;
      background: url(/media/images/common/ajax-loading.gif) no-repeat center center;
      * {
        opacity: 0.8;
        filter: alpha(opacity=80);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
      }
    }
    #offer-code-panel {
      @include breakpoint(($width-large - 1 $width-xlarge + 1) (orientation portrait)) {
        @include swap_direction(padding, 1em);
        .checkout__panel-title {
          display: block;
        }
      }
    }
    #shipping-panel,
    #review-panel {
      &.panel {
        select {
          @include breakpoint(($width-large - 1 $width-xlarge + 1) (orientation portrait)) {
            border-width: 1px;
          }
        }
      }
    }
    .title {
      & + .form-item {
        margin-top: 17px;
      }
    }
  }
  #offer-code-panel {
    .offer-code__input {
      label {
        @include breakpoint(($width-large - 1 $width-xlarge + 1) (orientation landscape)) {
          font-size: 10px;
        }
      }
    }
  }
}

@media (min-width: 40.0625em) {
  .checkout__content {
    #shipping-panel.loading {
      width: 100%;
      float: none;
      margin-left: 0;
      margin-right: 0;
      padding-right: 0;
    }
  }
}

#confirmation-page {
  .checkout-header {
    .checkout-header__title {
      padding-#{$ldirection}: 0;
    }
  }
  .checkout {
    &__content,
    &__sidebar {
      @include breakpoint(($width-large - 1 $width-xlarge + 1) (orientation portrait)) {
        @include swap_direction(margin, 20px 20px 0 10px);
      }
    }
  }
  .checkout__content {
    ul {
      margin-#{$ldirection}: 15px;
    }
  }
  .checkout__sidebar {
    ul {
      list-style: none;
      margin-top: 10px;
    }
  }
}

.samples-page {
  #samples-panel {
    .checkout-header {
      &__title {
        width: 100%;
        padding-left: 0;
      }
    }
  }
}

#favorites-panel {
  .product-list {
    min-height: 0px;
  }
}

#checkout_shipping_panel {
  .name-fields {
    .last-name {
      float: left;
    }
  }
}

.overlay-container.click_and_collect_map {
  z-index: 9999;
  .search-box-toggle {
    display: none;
  }
}

body.toolbar-drawer {
  padding-top: 0px;
}
