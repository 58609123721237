.enhanced-delivery-page {
  .checkout {
    .checkout__content {
      #shipping-panel.panel {
        header {
          .user_email {
            display: none;
          }
        }
        .edit-address-container {
          .edit-shipping-address {
            .city_container {
              label {
                display: block;
              }
            }
          }
        }
        .info-link-content {
          margin-top: 28px;
          margin-bottom: 35px;
          padding-#{$ldirection}: 16px;
          @include breakpoint($width-xlarge) {
            padding: 0;
          }
        }
        .new-address-container {
          .field-container {
            .form-item.address4 {
              margin-bottom: 22px;
            }
          }
          .field-container.state-container {
            .valid_marker,
            .invalid_marker {
              display: none;
            }
          }
          .city_container {
            display: block;
          }
        }
        .edit-shipping-address,
        .new-address-container {
          .field-container.state-container,
          .state_container {
            margin-bottom: 0;
            .state {
              margin-bottom: 0;
            }
            .prefecture_select {
              background-color: $color--white;
              background-image: none;
              color: $color--gray--dusty--disable;
              font-size: 14px;
              height: 54px;
              margin-bottom: 22px;
              text-decoration: auto;
            }
            &::after {
              #{$rdirection}: 25px;
              border: 5px solid transparent;
              border-top-color: $color--black;
              content: '';
              opacity: 0.5;
              pointer-events: none;
              position: absolute;
              top: 26px;
            }
          }
        }
        .billing-address-form-fields {
          .field-container--grid.form-item {
            margin-bottom: 0;
          }
          .country {
            .select2-container {
              .select2-chosen {
                font-size: 14px;
              }
            }
          }
          .field-container {
            .form-item.state {
              label {
                display: block;
                &::before {
                  margin-top: 19px;
                  @include breakpoint($width-xlarge) {
                    margin-top: 24px;
                  }
                }
              }
            }
          }
        }
        .bill-form-expand {
          & + input[name='PROCESS_BILLING_FORM'] + .billing-address-form-fields {
            .checkout__subtitle {
              display: none;
            }
          }
        }
        .ship-method-panel {
          .ship-method-group {
            margin: 0;
          }
          .shipmethod-home-delivery {
            display: block;
            margin-bottom: 16px;
            .picker-radio {
              margin: 0;
              padding: 16px 15px;
              &.checked {
                .picker-flag {
                  border-radius: 50%;
                  height: 18px;
                  margin: 0;
                  width: 18px;
                }
              }
              .picker-handle + .picker-radio {
                border: 0;
                padding: 0;
                .picker-handle {
                  display: none;
                }
              }
            }
          }
        }
      }
      .single-option-cc {
        float: $ldirection;
      }
    }
  }
  &.checkout {
    &.active-panel-shipping,
    &.active-panel-review {
      .checkout__sidebar {
        .ship-method-panel {
          display: none;
        }
      }
    }
  }
}
